<template>
    <div>
        <div class="device">
            <el-container v-loading="loading">
                <el-aside class="dialog-aside" style="height: 600px">
                    <el-tree :data="treeData" @node-click="handleNodeClick" :highlight-current="true"
                            :expand-on-click-node="false" default-expand-all>
                        <span class="custom-tree-node" slot-scope="{node, data}">
                            <span>
                              <i :class="data.icon"></i> {{ data.label }}
                            </span>
                        </span>
                    </el-tree>
                </el-aside>
                <el-main class="dialog-main" style="padding: 20px 20px 0px 20px;">
                    <el-row>
                        <el-col :span="20">
                            <span style="font-size: 16px;margin-top: 20px">{{this.curChoosePath}}</span>
                        </el-col>
                        <el-col :span="4">
                            <el-upload
                                    class="upload-demo"
                                    action=""
                                    multiple
                                    :limit="1">
                                <el-button size="mini" type="primary">上传文件</el-button>
                            </el-upload>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <el-row>
                            <el-col :span="10"><strong>文件名</strong></el-col>
                            <el-col :span="6"><strong>大小</strong></el-col>
                            <el-col :span="8"><strong>修改时间</strong></el-col>
                        </el-row>
                    </el-row>
                    <div style="margin-top: 10px">
                        <el-row v-for="(item, index) in fileData" style="margin-top: 8px">
                            <el-col :span="10">
                                <el-popover
                                        placement="right"
                                        trigger="hover">
                                    <div>
                                        <el-button type="success" circle icon="el-icon-download" title="下载" @click="fileOption(1, item.name)"></el-button>
                                        <el-button type="danger" circle icon="el-icon-delete" title="删除" @click="fileOption(2, item.name)"></el-button>
                                        <el-button type="info" circle icon="el-icon-edit" title="重命名" @click="fileOption(3, item.name)"></el-button>
                                    </div>
                                    <i slot="reference" v-if="item.directory == true" class="el-icon-folder" style="cursor:pointer;"
                                       @dblclick="chooseFile(item)"> {{item.name}}</i>
                                    <i slot="reference" v-if="item.directory == false" class="el-icon-document" style="cursor:pointer;"
                                       @dblclick="chooseFile(item)"> {{item.name}}</i>
                                </el-popover>
                            </el-col>
                            <el-col :span="6"><span>{{item.size}} Bytes</span></el-col>
                            <el-col :span="8"><span>{{item.updateTime}}</span></el-col>
                        </el-row>
                    </div>
                </el-main>
                <el-dialog title="请输入新文件名" :visible.sync="dialogVisible" width="20%" top="30vh" append-to-body>
                    <el-input v-model="fileNewName"></el-input>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="reDoRenameAction">确 定</el-button>
                    </span>
                </el-dialog>
            </el-container>
        </div>
    </div>
</template>

<script>
    import {ftpAction, fileDownload} from "../../api/communication/ftpApi";
    import global from "../../components/Global";
    let base = global.serverBase;
    export default {
        name: "ftpManage",
        inject: ['setUpgradeInfo'],
        components: {},
        data() {
            return {

                loading: false,
                treeData: [
                    {
                        id: 1,
                        label: '/',
                        icon: 'el-icon-folder',
                        children: [
                            /*{
                                id: 1,
                                label: '1、EMU固件',
                                icon: 'el-icon-folder'
                            }*/
                        ]
                    }
                ],
                curChoosePath:'',
                fileData:[],
                curChooseFile:{},
                dialogVisible:false,
                curActionParam:{},
                fileNewName:''
            };
        },
        methods : {

            doFtpAction (params){

                this.loading = true;
                ftpAction(params).then((res) => {

                    this.loading = false;
                    if (params.action == 'GetFileList') {

                        this.treeData.children = [];
                        this.fileData = [];
                        for(let i = 0; i < res.length; i++) {

                            let timestamp = res[i].timestamp
                            res[i].updateTime = this.dataFormat(timestamp)
                        }
                        if (params.file === '/') {

                            this.treeData[0].children = [];
                            let firstFiles = '';
                            for (let i = 0; i < res.length; i++) {

                                let fileInfo = res[i];
                                let child = {};
                                child.label = fileInfo.name;
                                if (i == 0) firstFiles = fileInfo.name;
                                if (fileInfo.directory == true) {

                                    child.icon = 'el-icon-folder';
                                } else {

                                    child.icon = 'el-icon-document';
                                }
                                this.treeData[0].children.push(child);
                            }
                            // 这边要初始化第一页
                            this.curChoosePath = "/" + firstFiles;
                            this.doFtpAction({action: 'GetFileList', file: this.curChoosePath})
                        } else {

                            this.fileData = res;
                        }
                    } else if (params.action === 'Crc32') {

                        this.curChooseFile.crc32 = res;
                        this.setUpgradeInfo(this.curChooseFile);
                    } else if(params.action === 'Delete' || params.action === 'Rename') {

                        let type = 'success';
                        let msg = res.msg;
                        if (msg.indexOf("失败") != -1) type = 'warning';
                        this.$message({
                            message: msg,
                            type: type
                        });
                        let params = {

                            action: 'GetFileList',
                            file: this.curChoosePath
                        }
                        this.doFtpAction(params);
                    }

                }).catch(err=> console.log(err));
            },
            dataFormat (timestamp) {

                if (timestamp ===null) {

                    return '--'
                }
                let dt = new Date(timestamp)
                return dt.getFullYear() + '-'
                    + this.addZero((dt.getMonth() + 1))
                    + '-' + this.addZero(dt.getDate())
                    + ' ' + this.addZero(dt.getHours())
                    + ':' + this.addZero(dt.getMinutes())
                    + ':' + this.addZero(dt.getSeconds())
            },
            addZero(num) {

                if (num.toString().length === 1) {

                    return "0" + num;
                }
                return num;
            },
            handleNodeClick(nodeData){

                let path = nodeData.label;
                // 这里判断顶级路径不需要加文件夹名称
                if (nodeData.label.indexOf("/") == -1) {

                    path = "/" + path;
                }
                this.curChoosePath = path;
                let params = {

                    action: 'GetFileList',
                    file: path
                };
                this.doFtpAction(params);
            },
            chooseFile(file) {

                file.path = this.curChoosePath;
                this.curChooseFile = file;
                let params = {

                    action: 'Crc32',
                    path: file.path + '/',
                    file: file.name
                };
                this.doFtpAction(params);
            },
            fileOption (type, fileName) {

                let params = {

                    action: '',
                    path: this.curChoosePath,
                    file: fileName
                }
                if (type === 1) {

                    fileDownload(params).then((res) => {

                        alert(JSON.stringify(res))
                        let blob = new Blob([res]);
                        let elink = document.createElement('a');
                        elink.download = fileName;
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        URL.revokeObjectURL(elink.href);
                        document.body.removeChild(elink);
                    }).catch(err=> console.log(err));
                } else if (type === 2) {

                    this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {

                        params.action = 'Delete';
                        this.doFtpAction(params);
                    }).catch(() => {});
                } else if (type === 3) {

                    params.action = 'Rename';
                    this.dialogVisible = true;
                    this.curActionParam = params;
                }
            },
            reDoRenameAction(){

                this.curActionParam.newname = this.fileNewName;
                this.doFtpAction(this.curActionParam);
                this.dialogVisible = false;
            }
        },
        mounted() {

        }
    }
</script>

<style scoped>
    .device >>> .dialog-aside {

        border: 1px #357cc5 solid;
        height: 720px;
        margin-right: 4px;
    }
    .device >>> .dialog-main {

        border: 1px #357cc5 solid
    }
</style>
<style>

</style>
